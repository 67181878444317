import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import ThemeToggle from "../utils/ThemeToggle"
import { useTheme } from "../utils/theme"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  const theme = useTheme()
  const [ isMounted, setIsMounted ] = useState(null)
  const { rhythm, scale } = theme

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          // fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer
        css={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          css={{
            marginRight: rhythm(1 / 2),
          }}
        >
          © {new Date().getFullYear()}, Ryan Mitts
        </div>
        {isMounted && <ThemeToggle />}
      </footer>
    </div>
  )
}

export default Layout
