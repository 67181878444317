import React from "react"
import Toggle from "react-toggle"
import { FaMoon, FaSun } from "react-icons/fa"
import "react-toggle/style.css"
import { css } from "@emotion/react"

import { useTheme } from "./theme"

const ThemeToggle = () => {
  const { preferredTheme } = useTheme()
  return (
    <Toggle
      checked={preferredTheme === "dark"}
      aria-label="Change the site theme"
      onChange={() => {
        if (preferredTheme === "light") {
          window.__setPreferredTheme("dark")
        } else {
          window.__setPreferredTheme("light")
        }
      }}
      css={css`
        &.react-toggle {
          .react-toggle-track-check,
          .react-toggle-track-x {
            width: 16px;
            height: 16px;
          }

          .react-toggle-track-check {
            svg {
              margin-left: -1px;
            }
          }

          .react-toggle-track-x {
            svg {
              margin-left: 3px;
            }
          }
          color: var(--shade1);
          :hover {
            .react-toggle-track {
              background-color: var(--shade6);
            }
            .react-toggle-thumb {
              background-color: var(--shade2);
              border-color: var(--shade2);
            }
          }
          .react-toggle-track {
            background-color: var(--shade6);
          }
          .react-toggle-thumb {
            background-color: var(--shade5);
            border-color: var(--shade5);
          }
        }
        &.react-toggle--checked {
          color: var(--shade1);
          :hover {
            .react-toggle-track {
              background-color: var(--white);
            }
            .react-toggle-thumb {
              background-color: var(--shade2);
              border-color: var(--shade2);
            }
          }
          .react-toggle-track {
            background-color: var(--white);
          }
          .react-toggle-thumb {
            background-color: var(--shade3);
            border-color: var(--shade3);
          }
        }
      `}
      icons={{
        checked: <FaMoon />,
        unchecked: <FaSun />,
      }}
    />
  )
}

export default ThemeToggle
